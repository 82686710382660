import React from "react";
import {
  Facebook,
  Instagram,
  Calendar,
  BarChart2,
  FileText,
  Mail,
  Users,
  Zap,
} from "lucide-react";
import "./Subscription.css";

// sk_test_51LBdPKElYk13qhXGc6KBS20GN8af8InAi62IJkrw0GQgSoH3a1yltARSDL7VPoK77kJnsAreAkTwsFs0xDUYgc070092EwWj4P
// pk_test_51LBdPKElYk13qhXG1iusfNh00qACsv7QmzfVf68JHqJ3N7hnz68UK7LaMZV0S9nLP8AA1mqhOcPTX31heLFNos85009UHGfkKd

const SubscriptionPlans = () => {
  const plans = [
    {
      name: "Free Plan",
      price: "$0",
      color: "#6a11cb",
      features: [
        {
          icon: <Users className="feature-icon-svg" />,
          text: "Connect up to 2 social media accounts",
        },
        {
          icon: <Calendar className="feature-icon-svg" />,
          text: "Schedule up to 10 posts per month",
        },
        {
          icon: <BarChart2 className="feature-icon-svg" />,
          text: "Basic analytics",
        },
        {
          icon: <FileText className="feature-icon-svg" />,
          text: "Access to 1 saved draft or template",
        },
        {
          icon: <Mail className="feature-icon-svg" />,
          text: "Community support (email only)",
        },
      ],
    },
    {
      name: "Paid Plan",
      price: "$10/month",
      color: "#2575fc",
      features: [
        {
          icon: <Users className="feature-icon-svg" />,
          text: "Connect up to 5 social media accounts",
        },
        {
          icon: <Zap className="feature-icon-svg" />,
          text: "Unlimited post scheduling",
        },
        {
          icon: <BarChart2 className="feature-icon-svg" />,
          text: "Advanced analytics",
        },
        {
          icon: <Calendar className="feature-icon-svg" />,
          text: "Basic content calendar",
        },
        {
          icon: <Mail className="feature-icon-svg" />,
          text: "Priority email support",
        },
      ],
    },
  ];

  return (
    <div className="subscription-plans">
      <h2 className="plans-title">Choose Your Plan</h2>
      <div className="plans-container">
        {plans.map((plan, index) => (
          <div
            key={plan.name}
            className="plan-card"
            style={{ "--plan-color": plan.color }}
          >
            <div className="plan-content">
              <h3 className="plan-name">{plan.name}</h3>
              <p className="plan-price">{plan.price}</p>
              <ul className="plan-features">
                {plan.features.map((feature, featureIndex) => (
                  <li key={featureIndex} className="feature-item">
                    <span className="feature-icon">{feature.icon}</span>
                    <span className="feature-text">{feature.text}</span>
                  </li>
                ))}
              </ul>
            </div>
            <button className="select-plan-btn">Select Plan</button>
          </div>
        ))}
      </div>
      <div className="supported-platforms">
        <Facebook />
        <Instagram />
      </div>
    </div>
  );
};

export default SubscriptionPlans;
