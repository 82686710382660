import axios from "axios";
import { API } from "../../../Config/config";

export const getPageInfo = async (pageId, access_token) => {
  const response = await axios
    .get(
      `${API.faceBookApiLink}/${pageId}?fields=followers_count,about,fan_count,engagement,published_posts,videos,photos,name&access_token=${access_token}`
    )
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((e) => {
      console.log(e);
      return e;
    });

  return response;
};


export const getGraphData = async (pageId, access_token) => {
  const response = await axios
    .get(
      `${API.faceBookApiLink}/${pageId}?fields=insights.metric(page_views_total,page_impressions,page_post_engagements).period(week)&since=2025-02-11&until=2025-02-19&access_token=${access_token}`
    )
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((e) => {
      console.log(e);
      return e;
    });

  return response;
}