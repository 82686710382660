"use client";
import axios from "axios";
import React, { useState, useEffect } from "react";
import "./Challenge.css";
import { API } from "../../Config/config";
import { StarIcon } from "lucide-react";
import {
  allAccounts,
  getPageLikesAndComments,
} from "../../Services/Facebook/GET/GetAccounts";
import { getPageInfo } from "../../Services/Facebook/GET/GetPageAnalytics";
const ChallengePanel = () => {
  const [challenges, setChallenges] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedChallenge, setSelectedChallenge] = useState(null);
  const userId = localStorage.getItem("userId");
  const [exsitingChallenge, setExistingChallenge] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [completed, setCompleted] = useState(false);
  const [points, setPoints] = useState(0);
  const [totalFollowers, setTotalFollowers] = useState(0);


  const getLikesAndComments = async () => {
    const response = await allAccounts();
    console.log(response);

    if (response.status === 200) {
      const allAccountsPromise = response?.data?.data?.map(async (item) => {
        return await getPageLikesAndComments(item.id, item.access_token);
      });

      const allAccountsRaw = await Promise.all(allAccountsPromise);

      let likes = 0;
      let totalComments = 0;

      allAccountsRaw.map((item) => {
        item.data.data.map((post) => {
          likes += post.likes.summary.total_count;
          totalComments += post.comments.summary.total_count;
        });
      });

      console.log(likes);
      console.log(totalComments);

      await axios
        .patch(`${API.apiUrl}/users/${userId}`, {
          socialPoints: (likes + totalComments) * 5,
        })
        .then((res) => {
          console.log(res);

          axios.patch(`${API.apiUrl}/countpoints/${userId}`, {}).then((res) => {
            console.log(res);
          }).catch((e) => {
            console.log(e);
          })
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  useEffect(() => {
    getLikesAndComments();
  }, []);

  const findFollowers = async () => {
    const response = await allAccounts();

    if (response.status !== 200) {
      return;
    }

    const allAccountsPromise = response?.data?.data?.map(async (item) => {
      return await getPageInfo(item.id, item.access_token);
    });
    const allAccountsRaw = await Promise.all(allAccountsPromise);
    console.log(allAccountsRaw);

    let sum = 0;

    allAccountsRaw.map((item) => {
      sum += item.data.followers_count;
    });

    setTotalFollowers(sum);
  };

  useEffect(() => {
    findFollowers();
  }, []);

  useEffect(() => {
    const findUserInfo = () => {
      axios
        .get(`${API.apiUrl}/users/${userId}`)
        .then((res) => {
          setUserInfo(res.data);

          setPoints(res.data.totalPoints);
        })
        .catch((e) => {
          console.log(e);
        });
    };

    findUserInfo();
  }, []);

  const fetchChallenges = async () => {
    try {
      const response = await fetch(`${API.apiUrl}/challenges`);
      if (!response.ok) {
        throw new Error("Failed to fetch challenges");
      }
      const data = await response.json();
      console.log(data);
      console.log(userInfo.category);

      const filterByCategory = data.filter(
        (item) => item.category === userInfo.category
      );

      setChallenges([...filterByCategory]);
      setLoading(false);
    } catch (err) {
      setError("Failed to load challenges. Please try again later.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchChallenges();
  }, [userInfo]);



  const handleChallengeSelect = (challenge) => {
    if (challenge.type === "followers") {
      axios
        .post(`${API.apiUrl}/checkfollowers/${userId}`, {
          mediaType: { type: challenge.type },
          totalFollowers: totalFollowers,
        })
        .then((res) => {
          console.log(res);
        })
        .catch((e) => {
          console.log(e);
        });
    }

    setCompleted(false);
    if (challenge) {
      axios
        .get(`${API.apiUrl}/findchallenge/` + userId + "/" + challenge._id)
        .then((res) => {
          if (res.status === 200) {
            setExistingChallenge(res.data.challenge);
          } else {
            setExistingChallenge({});
          }
        })
        .catch((e) => {
          setExistingChallenge({});
        });

      userInfo?.completedChallenges.map((item) => {
        if (item.challengeId === challenge._id) {
          console.log("Completed");
          setCompleted(true);
        }
      });
    }

    setSelectedChallenge(challenge);
  };

  const handleStartChallenge = async () => {
    const payload = {
      challengeId: selectedChallenge._id,
      challengeName: selectedChallenge.challenge,
      completed: 0,
      total: selectedChallenge.total,
      status: "started",
      mediaType: selectedChallenge.mediaType,
      points: selectedChallenge.points,
      days: selectedChallenge.days,
      type: selectedChallenge.type,
    };

    axios
      .patch(`${API.apiUrl}/users/` + userId, {
        challenge: payload,
      })
      .then((res) => {
        console.log(res);
        setExistingChallenge(payload);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  if (loading) {
    return <div className="loading">Loading challenges...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="challenge-panel">
      <div className="panel-header">
        <h1 className="panel-title">Epic Challenges Await</h1>
        <div className="points-container">
          <p className="points">
            {" "}
            <StarIcon /> Points: {points}
          </p>
        </div>
      </div>
      <div className="challenge-container">
        <div className="challenge-list">
          {challenges.map((challenge) => (
            <div
              key={challenge._id}
              className={`challenge-item ${
                selectedChallenge?._id === challenge._id ? "selected" : ""
              }`}
              onClick={() => handleChallengeSelect(challenge)}
            >
              <span className="challenge-text">{challenge.challenge}</span>
              <span className="challenge-arrow">→</span>
            </div>
          ))}
        </div>
        <div className="challenge-details">
          {selectedChallenge && !exsitingChallenge.challengeName ? (
            <>
              <h2 className="selected-title">Selected Challenge</h2>
              <p className="selected-description">
                {selectedChallenge.challenge}
              </p>
              <p className="selected-description">
                {" "}
                <StarIcon /> You will earn {selectedChallenge.points} points to
                complete this challenge!
              </p>
              {(userInfo.challenge?.challengeName ||
                userInfo.challenge?.status !== "completed") &&
                !completed && (
                  <button
                    className="start-button"
                    onClick={handleStartChallenge}
                  >
                    Start Challenge
                  </button>
                )}

              {completed && (
                <>
                  {" "}
                  <p className="selected-description green">
                    You have already completed this challenge!
                  </p>{" "}
                </>
              )}
            </>
          ) : selectedChallenge && exsitingChallenge.challengeName ? (
            <>
              <h2 className="selected-title">Selected Challenge</h2>
              <p className="selected-description">
                {selectedChallenge.challenge}
              </p>
              <p className="selected-description">
                {" "}
                STATUS: {exsitingChallenge.status.toUpperCase()}
              </p>
              <p>
                Uploaded: {exsitingChallenge.completed} /{" "}
                {exsitingChallenge.total}
                {exsitingChallenge.type !== "followers"
                  ? " posts"
                  : " followers"}
              </p>
            </>
          ) : (
            <p className="no-selection">
              Select a challenge to begin your epic journey!
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChallengePanel;
