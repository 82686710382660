"use client";

import { useState, useEffect } from "react";
import "./ContentSchedule.css";
import { allAccounts } from "./Services/Facebook/GET/GetAccounts";
import ContentScheduleForm from "./components/ContentSchedule/ContentScheduleForm";
import axios from "axios";
import { API } from "./Config/config";
import { Link } from "react-router-dom";
import { Info } from "lucide-react";

function ContentSchedule() {
  const [pages, setPages] = useState([]);
  const [pageAccessToken, setPageAccessToken] = useState("");
  const [pageId, setPageId] = useState("");
  const [pageName, setPageName] = useState("");
  const [pageSelected, setPageSelected] = useState(false);
  const userId = localStorage.getItem("userId");
  const [user, setUser] = useState({});

  const getUserInfo = () => {
    axios
      .get(`${API.apiUrl}/users/${userId}`)
      .then((res) => {
        console.log(res);
        setUser(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getUserInfo();
  }, [userId]); // Added userId as a dependency

  const getData = async () => {
    const response = await allAccounts();
    console.log(response);

    if (response.status === 200) {
      setPages(response.data.data);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const handlePageInfo = (acessToken, pageId, pageName) => {
    setPageAccessToken(acessToken);
    setPageId(pageId);
    setPageName(pageName);
    setPageSelected(true);
  };

  const displayPages =
    user.subscriptionType === "free" ? pages.slice(0, 2) : pages;

  return (
    <>
      {displayPages.length > 0 && pageSelected === false ? (
        <>
          <p>
            We found {pages.length} page
            {displayPages.length !== 1 ? "s" : ""} linked with this profile!
          </p>
          {user.subscriptionType === "free" && pages.length > 2 && (
            <p>Upgrade your subscription to access all {pages.length} pages.</p>
          )}
          <div className="pages-container">
            {displayPages.map((item) => (
              <div
                key={item.id}
                onClick={() =>
                  handlePageInfo(item.access_token, item.id, item.name)
                }
              >
                <h4>{item.name}</h4>
                <p>{item.category}</p>
              </div>
            ))}
          </div>
        </>
      ) : null}
      {user.subscriptionType === "free" && (
        <div className="info-box">
        <Info className="info-icon" />
        <div className="info-content">
          <p>Can't see all your pages? Free tier only allows connection to 2 pages.</p>
          <p>
            <Link to="/subscription" className="upgrade-link">
              Upgrade your plan
            </Link>{" "}
            to connect all of your pages!
          </p>
        </div>
      </div>
      )}

      {pageSelected && (
        <ContentScheduleForm
          pageAccessToken={pageAccessToken}
          pageId={pageId}
          pageName={pageName}
        />
      )}
    </>
  );
}

export default ContentSchedule;
