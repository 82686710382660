import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import './Instagram.css'
import { allAccounts } from '../../Services/Facebook/GET/GetAccounts'
import axios from 'axios'
import logo from "../../Images/instalogo.png"
import { API } from "../../Config/config";
import InstagramLogin from './Login/InstaLogin'

export default function Instagram() {
  const [accounts, setAccounts] = useState([])
  const navigate = useNavigate()
  const access_token = localStorage.getItem("access_token")

  const getFacebookAccountDetails = async (accountId) => {
    try {
      const response = await axios.get(
        `${API.faceBookApiLink}/${accountId}?fields=name,picture,access_token&access_token=${access_token}`
      )
      return response.data
    } catch (error) {
      console.error("Error fetching Facebook account details:", error)
      return null
    }
  }

  const getInstagramAccountDetails = async (instagramAccountId) => {
    try {
      const response = await axios.get(
        `${API.faceBookApiLink}/${instagramAccountId}?fields=username,name,profile_picture_url&access_token=${access_token}`
      )
      return response.data
    } catch (error) {
      console.error("Error fetching Instagram account details:", error)
      return null
    }
  }

  const getAllData = async () => {
    try {
      const response = await allAccounts()
      const facebookAccounts = response.data.data

      const accountsWithDetails = await Promise.all(
        facebookAccounts.map(async (account) => {
          const facebookDetails = await getFacebookAccountDetails(account.id)
          const instagramResponse = await axios.get(
            `${API.faceBookApiLink}/${account.id}?fields=connected_instagram_account&access_token=${access_token}`
          )
          const connectedInstagramAccount = instagramResponse.data.connected_instagram_account

          if (connectedInstagramAccount) {
            const instagramDetails = await getInstagramAccountDetails(connectedInstagramAccount.id)
            return {
              facebook: facebookDetails,
              instagram: instagramDetails,
            }
          }

          return null
        })
      )

      console.log(accountsWithDetails);
      

      setAccounts(accountsWithDetails.filter(account => account !== null))
    } catch (error) {
      console.error("Error fetching accounts:", error)
    }
  }

  useEffect(() => {
    getAllData()
  }, [])

  const handleAccountClick = (account) => {
    navigate('/instagram-schedule', {
      state: {
        pageAccessToken: account.facebook.access_token,
        instagramAccountId: account.instagram.id,
        username: account.instagram.username,
        facebookPageId: account.facebook.id
      }
    })
  }

  return (
    <div className="instagram-container">
      <InstagramLogin/>
      <h1>Linked Accounts</h1>
      <div className="accounts-grid">
        {accounts.map((account, index) => (
          <div key={index} className="account-card" onClick={() => handleAccountClick(account)}>
            <div className="facebook-info">
              <img src={account.facebook.picture.data.url} alt={account.facebook.name} className="profile-picture" />
              <h2>{account.facebook.name}</h2>
              <p className="account-type">Facebook</p>
            </div>
            <div className="instagram-info">
              <img 
                src={account.instagram.profile_picture_url || logo} 
                alt={account.instagram.username} 
                className="profile-picture" 
              />
              <h3>{account.instagram.name}</h3>
              <p className="username">@{account.instagram.username}</p>
              <p className="account-type">Instagram</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
