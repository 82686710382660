import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Sidebar.css"; // Import appropriate CSS file
import logo from "../Images/logo.webp";
import { Book, Calendar, CrownIcon, Facebook, GitGraphIcon, Home, Instagram, LucideGitGraph, SaveAllIcon, Timer, User2, UserCheck2Icon, UserCircle } from "lucide-react";

const Sidebar = () => {
  const navigate = useNavigate();

  const userId = localStorage.getItem("userId");

  const logout = () => {
    localStorage.removeItem("userId");
    navigate("/");
  };

  const [selectedState, setSelectedState] = useState("home");


  return (
    <div className="sidebar">
      {/* Logo at the top left */}
      <div className="logo">
        <img src={logo} alt="Logo"/>{" "}
        {/* Add your logo path */}
      </div>

      {/* Sidebar links */}

      {userId && (
        <>
          <Link to="/home" className={selectedState === "home" && "selectedItem"} onClick={() => setSelectedState("home")}><Home/> Home</Link>
          <Link to="/profile" className={selectedState === "profile" && "selectedItem"} onClick={() => setSelectedState("profile")}><User2/> Profile</Link>
          <Link to="/analytics" className={selectedState === "analytics" && "selectedItem"} onClick={() => setSelectedState("analytics")}> <LucideGitGraph/> Analytics</Link>
          <Link to="/challenges" className={selectedState === "challenges" && "selectedItem"} onClick={() => setSelectedState("challenges")}> <UserCircle/> Challenges</Link>
          <Link to="/leaderboard" className={selectedState === "leaderboard" && "selectedItem"} onClick={() => setSelectedState("leaderboard")}> <GitGraphIcon/> Leaderboard</Link>
          {/* <Link to="/content-library"> <Book/> Library</Link> */}
          <Link to="/usercontent" className={selectedState === "usercontent" && "selectedItem"} onClick={() => setSelectedState("usercontent")}> <UserCheck2Icon/> My Content</Link>
          <Link to="/schedule" className={selectedState === "schedule" && "selectedItem"} onClick={() => setSelectedState("schedule")}> <Timer/> Content Schedule</Link>
          <Link to="/calender" className={selectedState === "calender" && "selectedItem"} onClick={() => setSelectedState("calender")}> <Calendar/> Content Calendar</Link>
          {/* <Link to="/contentreccomendation"> <SaveAllIcon/> Content Recommendation</Link> */}
          {/* <Link to="/post-canvas">Post Canvas</Link>
          <Link to="/video-canvas">Video Canvas</Link> */}
        </>
      )}

      <Link to="/instagram"><Instagram/> Instagram</Link>
      <Link to="/facebook"> <Facebook/> Facebook</Link>
      <Link to="/subscription"> <span className="flex"> <CrownIcon/> Subscription </span></Link>

      {/* Add Login/Logout and Register at the bottom */}
      {userId ? (
        <>
          <button onClick={() => logout()}>Logout</button>
        </>
      ) : (
        <>
          <Link to="/login">Login</Link>
        </>
      )}
      <Link to="/register">Register</Link>
    </div>
  );
};

export default Sidebar;
