import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment'
import "./Calender.css";
import axios from "axios";
import { API } from "../../Config/config";

export default function Calender() {
  const localizer = momentLocalizer(moment);
  const userId = localStorage.getItem("userId");
  // const myEventsList = [
  //   {
  //     title: 'Social Media Campaign',
  //     start: new Date(2025, 1, 20, 10, 0), // Year, Month (0-indexed), Day, Hour, Minute
  //     end: new Date(2025, 1, 20, 12, 0),
  //     resource: { platform: 'Facebook', postType: 'Ad Campaign' }, // Custom data
  //     tooltip: 'Campaign launch for Spring Collection', // Tooltip on hover
  //   },
  //   {
  //     title: 'Instagram Product Post',
  //     start: new Date(2025, 1, 22, 14, 0),
  //     end: new Date(2025, 1, 22, 15, 0),
  //     resource: { platform: 'Facebook', postType: 'Ad Campaign' }, // Custom data
  //     tooltip: 'Campaign launch for Spring Collection', // Tooltip on hover
  //   },
  //   {
  //     title: 'Facebook Ad Campaign Review',
  //     start: new Date(2025, 1, 25, 16, 0),
  //     end: new Date(2025, 1, 25, 17, 0),
  //     resource: { platform: 'Facebook', postType: 'Ad Campaign' }, // Custom data
  //     tooltip: 'Campaign launch for Spring Collection', // Tooltip on hover
  //   },
  //   {
  //     title: 'Weekly Content Planning',
  //     start: new Date(2025, 1, 27, 9, 0),
  //     end: new Date(2025, 1, 27, 11, 0),
  //     resource: { platform: 'Facebook', postType: 'Ad Campaign' }, // Custom data
  //     tooltip: 'Campaign launch for Spring Collection', // Tooltip on hover
  //   },
  //   {
  //     title: 'User Engagement Report',
  //     start: new Date(2025, 1, 28, 13, 0),
  //     end: new Date(2025, 1, 28, 14, 0),
  //     resource: { platform: 'Facebook', postType: 'Ad Campaign' }, // Custom data
  //     tooltip: 'Campaign launch for Spring Collection', // Tooltip on hover
  //   },
  // ];
  
  const [myEventsList, setMyEventsList] = useState([]);
  const getData = async () => {
    const response = await axios.get(`${API.apiUrl}/userscheduledposts/${userId}`);
    console.log(response);

    const data = response.data.map((item) => {
      return {
        title: `Facebook ${item.mediaType} post scheduled`,
        start: item.scheduledDate,
        end: item.scheduledDate,
        resource: { platform: 'Facebook', postType: item.mediaType },
        tooltip: `${item.mediaType} post scheduled`, 
        color: "'#E91E63"
      }
    })

    console.log(data);
    setMyEventsList(data);
    
  }


  useEffect(() => {
    getData();
  }, [])
  return (
    <div className="calenderContainer">
        <h1>Content Schedule Calendar</h1>
      <Calendar
        localizer={localizer}
        events={myEventsList}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 700, backgroundColor: "rgba(255, 255, 255, 0.2)", padding: "20px", boxShadow: "0px 0px 10px black", borderRadius: "10px" }}
      />
    </div>
  );
}
