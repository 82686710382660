"use client"

import { useEffect, useState } from "react"
import "./ContentScheduleForm.css"
import axios from "axios"
import { API } from "../../Config/config"
import { NotificationContainer, NotificationManager } from "react-notifications"
import { MessageCircleWarning } from "lucide-react"

export default function ContentScheduleForm({ pageName, pageId, pageAccessToken }) {
  const [content, setContent] = useState("")
  const [date, setDate] = useState("")
  const [time, setTime] = useState("")
  const [scheduledPosts, setScheduledPosts] = useState([])
  const [posted, setPosted] = useState([])
  const [media, setMedia] = useState({ type: "text", url: "" })
  const userId = localStorage.getItem("userId")

  const [user, setUser] = useState({})
  const [existingPosts, setExistingPosts] = useState([])

  const [hashtags, setHashtags] = useState([])
  const [hashtagFilter, setHashtagFilter] = useState("")
  const [categoryFilter, setCategoryFilter] = useState("")
  const [nicheFilter, setNicheFilter] = useState("")
  const [allUsers, setAllUsers] = useState([])

  const [searchTerm, setSearchTerm] = useState("")
  const [searchResults, setSearchResults] = useState([])
  const [selectedUser, setSelectedUser] = useState(null)

  const getAllUsers = () => {
    axios
      .get(`${API.apiUrl}/userhashtags`)
      .then((res) => {
        setAllUsers(res.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    getAllUsers()
  }, [])

  const addHashtagToContent = (hashtag) => {
    setContent((prev) => {
      // Add a space if the content doesn't end with one
      const spacer = prev.endsWith(" ") || prev === "" ? "" : " "
      return `${prev}${spacer}#${hashtag} `
    })
  }

  const filteredHashtags = hashtags.filter((tag) => {
    const matchesSearch = tag.hashtag.toLowerCase().includes(hashtagFilter.toLowerCase())
    const matchesCategory = categoryFilter === "" || tag.category === categoryFilter
    const matchesNiche = nicheFilter === "" || tag.niche === nicheFilter
    return matchesSearch && matchesCategory && matchesNiche
  })

  const getHashtags = () => {
    axios
      .get(`${API.apiUrl}/hashtags`)
      .then((res) => {
        setHashtags(res.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    getHashtags()
  }, []) // Added empty dependency array

  const getUserInfo = () => {
    axios
      .get(`${API.apiUrl}/users/${userId}`)
      .then((res) => {
        console.log(res)
        setUser(res.data)
      })
      .catch((e) => {
        console.log(e)
      })

    axios
      .get(`${API.apiUrl}/userscheduledposts/${userId}`)
      .then((res) => {
        console.log(res)
        setExistingPosts(res.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    getUserInfo()
  }, []) // Removed userId from dependency array

  const searchUsers = (term) => {
    if (!term.trim()) {
      setSearchResults([])
      return
    }

    const filteredUsers = allUsers.filter((user) => user.userName.toLowerCase().includes(term.toLowerCase()))
    setSearchResults(filteredUsers)
  }

  const uploadToCloudinary = async (e) => {
    try {
      const file = e.target.files[0]
      const fileType = file.type.startsWith("video") ? "video" : "image"

      const formData = new FormData()
      formData.append("file", file) // Directly upload file without base64 conversion
      formData.append("upload_preset", "bhhbjgqb")

      const cloudinaryUrl = `https://api.cloudinary.com/v1_1/dzusmmrmh/${fileType}/upload`
      const cloudinaryRes = await axios.post(cloudinaryUrl, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })

      const mediaUrl = cloudinaryRes.data.secure_url
      setMedia({ type: fileType, url: mediaUrl })
      console.log("Uploaded media URL:", mediaUrl)
    } catch (error) {
      console.error("Error uploading media:", error)
    }
  }

  const addToDb = async () => {
    const payload = {
      scheduledDate: new Date(`${date}T${time}`),
      mediaUrl: media.url,
      mediaType: media.type,
      access_token: pageAccessToken,
      pageId,
      content,
      userId,
    }

    axios
      .post(`${API.apiUrl}/schedulecontent`, payload)
      .then((res) => {
        console.log(res)
        NotificationManager.success("Content has been scheduled!")

        axios
          .post(`${API.apiUrl}/checkchallenges/${userId}`, { mediaType: media })
          .then((res) => {
            console.log(res)
          })
          .catch((e) => {
            console.log(e)
          })

        setContent("")
        setDate("")
        setTime("")
        setMedia({ type: "", url: "" })
      })
      .catch((e) => {
        console.log(e)
        NotificationManager.error("Something went wrong!")
      })

    const payload2 = {
      content,
      niche: user.niche,
      category: user.category,
      userId: userId,
    }
    axios
      .post(`${API.apiUrl}/hashtagtracker`, payload2)
      .then((res) => {
        console.log(res)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const [currentDate, setCurrentDate] = useState("")

  // Set the min date to today when the component mounts
  useEffect(() => {
    const today = new Date().toISOString().split("T")[0] // Get today's date in YYYY-MM-DD format
    setCurrentDate(today) // Set today's date as the default value
  }, [])
  return (
    <div>
      <NotificationContainer />
      {user.subscriptionType === "free" && existingPosts.length >= 10 ? (
        <>
          <div>
            <MessageCircleWarning size={100} color="red" />
            <h1>You have reached the monthly limit of scheduling 2 posts per month</h1>
            <h3>Upgrade to premium to schedule more posts</h3>
          </div>
        </>
      ) : (
        <>
          <div className="schedule-container">
            <div className="form">
              <p>Connected to {pageName}</p>
              <h2>Content Schedule Page</h2>
              <textarea placeholder="Content" value={content} onChange={(e) => setContent(e.target.value)} />

              {/* Hashtags Section */}
              <div className="hashtags-container">
                <h3>Popular Hashtags</h3>
                <div className="hashtags-filter">
                  <input
                    type="text"
                    placeholder="Search hashtags..."
                    onChange={(e) => setHashtagFilter(e.target.value)}
                    className="hashtag-search"
                  />
                  <select onChange={(e) => setCategoryFilter(e.target.value)} className="hashtag-filter">
                    <option value="">All Categories</option>
                    {[...new Set(hashtags.map((tag) => tag.category))].map(
                      (category) =>
                        category && (
                          <option key={category} value={category}>
                            {category}
                          </option>
                        ),
                    )}
                  </select>
                  <select onChange={(e) => setNicheFilter(e.target.value)} className="hashtag-filter">
                    <option value="">All Niches</option>
                    {[...new Set(hashtags.map((tag) => tag.niche))].map(
                      (niche) =>
                        niche && (
                          <option key={niche} value={niche}>
                            {niche}
                          </option>
                        ),
                    )}
                  </select>
                </div>
                <div className="hashtags-list">
                  {filteredHashtags.map((tag, index) => (
                    <div key={index} className="hashtag-pill" onClick={() => addHashtagToContent(tag.hashtag)}>
                      #{tag.hashtag}
                      <span className="hashtag-count">{tag.count}</span>
                    </div>
                  ))}
                </div>
              </div>

              <input type="file" name="media" onChange={uploadToCloudinary} />
              <br />
              <br />
              {media.url &&
                (media.type === "image" ? (
                  <img src={media.url || "/placeholder.svg"} alt="post" width={300} />
                ) : (
                  <video src={media.url} controls width={300} />
                ))}
              <input
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                min={new Date().toISOString().split("T")[0]}
              />
              <input type="time" value={time} onChange={(e) => setTime(e.target.value)} />
              <button onClick={addToDb}>Schedule</button>
            </div>
            <div className="posted-content">
              <h3>Search for a content creator</h3>
              <p>Find out their popular hastags!</p>

              <input
                type="search"
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value)
                  searchUsers(e.target.value)
                }}
              />

              {searchResults.length > 0 && (
                <div className="search-results">
                  {searchResults.map((user) => (
                    <div key={user._id} className="user-result" onClick={() => setSelectedUser(user)}>
                      <p className="user-name">{user.userName}</p>
                    </div>
                  ))}
                </div>
              )}

              {selectedUser && (
                <div className="selected-user-hashtags">
                  <h4>{selectedUser.userName}'s Hashtags</h4>
                  {selectedUser.hashtags && selectedUser.hashtags.length > 0 ? (
                    <div className="hashtags-list">
                      {selectedUser.hashtags.map((tag, index) => (
                        <div key={index} className="hashtag-pill" onClick={() => addHashtagToContent(tag.hashtag)}>
                          #{tag.hashtag}
                          {tag.count && <span className="hashtag-count">{tag.count}</span>}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p>No hashtags found for this user.</p>
                  )}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

