import axios from "axios";
import { API } from "../../../Config/config";

const access_token = localStorage.getItem("access_token");
export const allAccounts = async () => {
  const response = await axios
    .get(`${API.faceBookApiLink}/me/accounts?access_token=${access_token}`)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e;
    });

  return response;
};

export const getPageLikesAndComments = async (pageId, pageAccessToken) => {
  const response = await axios
    .get(
      `${API.faceBookApiLink}/${pageId}/posts?fields=id,message,likes.summary(true),comments.summary(true)&access_token=${pageAccessToken}`
    )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e;
    });

  return response;
};