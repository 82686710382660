"use client"

import { useEffect, useState } from "react"
import "./UserContent.css"
import loaderImg from "../Images/loader.gif"
import axios from "axios"
import { API } from "../Config/config"
import Select from "react-select"

const mediaTypeFilter = [
  { value: "video", label: "Video" },
  { value: "image", label: "Image" },
  { value: "text", label: "Text" },
]

const customStyles = {
  singleValue: (provided) => ({
    ...provided,
    color: "white", // Change selected value color to white
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#37305E",
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: "#37305E",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#d3d3d3" : "#37305E",
    color: state.isFocused ? "#000" : "#fff",
  }),
}

export default function UserContent() {
  const [selected, setSelected] = useState("scheduled")
  const [loader, setLoader] = useState(true)
  const [content, setContent] = useState([])
  const [selectedMediaType, setSelectedMediaType] = useState(null)
  const [fromDate, setFromDate] = useState("")
  const [toDate, setToDate] = useState("")

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(6)
  const [totalPages, setTotalPages] = useState(1)
  const [totalItems, setTotalItems] = useState(0)

  const getContent = () => {
    setLoader(true)
    const mediaType = selectedMediaType ? selectedMediaType.value : null

    axios
      .get(`${API.apiUrl}/schedulecontent/${selected}`, {
        params: {
          mediaType,
          from: fromDate,
          to: toDate,
          // Keep these parameters in case the API is updated in the future
          page: currentPage,
          limit: itemsPerPage,
        },
      })
      .then((res) => {
        // Store all data for client-side pagination
        const allData = Array.isArray(res.data.data) ? res.data.data : res.data
        setTotalItems(allData.length)
        setTotalPages(Math.ceil(allData.length / itemsPerPage))

        // Implement client-side pagination
        const startIndex = (currentPage - 1) * itemsPerPage
        const endIndex = startIndex + itemsPerPage
        const paginatedData = allData.slice(startIndex, endIndex)

        setContent(paginatedData)
        setLoader(false)
      })
      .catch((e) => {
        console.error(e)
        setLoader(false)
      })
  }

  useEffect(() => {
    getContent()
  }, [selected, selectedMediaType, fromDate, toDate, currentPage, itemsPerPage])

  const resetFilters = () => {
    setSelectedMediaType(null)
    setFromDate("")
    setToDate("")
    setCurrentPage(1)
    getContent() // Fetch data with default filters
  }

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  // Generate pagination buttons
  const renderPaginationButtons = () => {
    const buttons = []

    // Previous button
    buttons.push(
      <button
        key="prev"
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="pagination-button"
      >
        &laquo;
      </button>,
    )

    // Page number buttons
    const maxButtonsToShow = 5
    let startPage = Math.max(1, currentPage - Math.floor(maxButtonsToShow / 2))
    const endPage = Math.min(totalPages, startPage + maxButtonsToShow - 1)

    if (endPage - startPage + 1 < maxButtonsToShow) {
      startPage = Math.max(1, endPage - maxButtonsToShow + 1)
    }

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`pagination-button ${currentPage === i ? "active" : ""}`}
        >
          {i}
        </button>,
      )
    }

    // Next button
    buttons.push(
      <button
        key="next"
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="pagination-button"
      >
        &raquo;
      </button>,
    )

    return buttons
  }

  return (
    <div className="content-library-container">
      {/* Content Library Header */}
      <div className="library-header">
        <h2>My Library</h2>
      </div>

      {/* Toggler */}
      <div className="toggler">
        <div className="inner-toggle">
          <span className={`${selected === "scheduled" ? "selected" : ""}`} onClick={() => setSelected("scheduled")}>
            Scheduled Content
          </span>
          <span className={`${selected === "posted" ? "selected" : ""}`} onClick={() => setSelected("posted")}>
            Posted Content
          </span>
        </div>
      </div>

      {/* Filters */}
      <div className="filter-bar">
        <Select
          defaultValue={selectedMediaType}
          onChange={(option) => setSelectedMediaType(option)}
          options={mediaTypeFilter}
          className="media-filter"
          placeholder={"Media Type"}
          styles={customStyles}
        />
        <div>
          <span>From: </span>
          <input type="date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
        </div>
        <div>
          <span>To: </span>
          <input type="date" value={toDate} onChange={(e) => setToDate(e.target.value)} />
        </div>

        <button onClick={resetFilters}> Reset Filters </button>
      </div>

      {/* Content Display */}
      {loader ? (
        <div className="loader-container">
          <img src={loaderImg || "/placeholder.svg"} alt="loader" width={200} />
        </div>
      ) : (
        <div className="content-grid">
          {content.length > 0 ? (
            content.map((item) => (
              <div className="content-container" key={item._id}>
                {item.mediaType === "image" ? (
                  <img src={item.mediaUrl || "/placeholder.svg"} alt="media" />
                ) : item.mediaType === "video" ? (
                  <video src={item.mediaUrl} controls></video>
                ) : (
                  <div className="text-container">{item.content}</div>
                )}
                <div className="text-container">
                  <h3>Content Info:</h3>
                  <hr />
                  <p>
                    <strong>Caption: </strong> {item.content}
                  </p>
                  <p>
                    <strong>Scheduled For: </strong> {new Date(item.scheduledDate).toLocaleDateString()}
                  </p>
                  <p>
                    <strong>Status: </strong>
                    <span className="status green">{item.status.toUpperCase()}</span>
                  </p>
                </div>
              </div>
            ))
          ) : (
            <div className="loader-container">
              <h1>No content found!</h1>
            </div>
          )}
        </div>
      )}

      {/* Pagination */}
      {!loader && content.length > 0 && (
        <>
        <div className="pagination-container">
          {renderPaginationButtons()}
        </div>
        <br/>
         <div className="pagination-info">
         Page {currentPage} of {totalPages} ({totalItems} items)
       </div>
       </>
      )}
    </div>
  )
}

