"use client"

import { useState, useEffect } from "react"
import { allAccounts } from "../../../Services/Facebook/GET/GetAccounts"
import { getGraphData, getPageInfo } from "../../../Services/Facebook/GET/GetPageAnalytics"
import "./Analytics.css"
import { CameraIcon, FanIcon, PictureInPicture2Icon, User, Video, Loader2 } from "lucide-react"
import { Line } from "react-chartjs-2"
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement } from "chart.js"

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement)

export default function Analytics() {
  const [pages, setPages] = useState([])
  const [pageAccessToken, setPageAccessToken] = useState("")
  const [selectedPage, setSelectedPage] = useState({})
  const [selectedPageId, setSelectedPageId] = useState("")
  const [graphData, setGraphData] = useState([])

  // Loading states
  const [isLoadingPages, setIsLoadingPages] = useState(true)
  const [isLoadingPageInfo, setIsLoadingPageInfo] = useState(false)
  const [isLoadingGraphData, setIsLoadingGraphData] = useState(false)

  // Fetch account data and set initial state
  const getData = async () => {
    setIsLoadingPages(true)
    try {
      const response = await allAccounts()

      if (response.status === 200) {
        setPages(response.data.data)
        const firstPage = response.data.data[0]
        setPageAccessToken(firstPage?.access_token || "")
        setSelectedPageId(firstPage?.id || "")
      }
    } catch (error) {
      console.error("Error fetching accounts:", error)
    } finally {
      setIsLoadingPages(false)
    }
  }

  // Fetch page information whenever `selectedPageId` changes
  const fetchPageInfo = async () => {
    if (!selectedPageId) return // Guard clause to avoid unnecessary calls

    setIsLoadingPageInfo(true)
    setIsLoadingGraphData(true)

    try {
      const pageResponse = await getPageInfo(selectedPageId, pageAccessToken)
      setSelectedPage(pageResponse.data || {})
      setIsLoadingPageInfo(false)

      const graphResponse = await getGraphData(selectedPageId, pageAccessToken)
      setGraphData(graphResponse?.data?.insights?.data || [])
      setIsLoadingGraphData(false)
    } catch (error) {
      console.error("Error fetching page info or graph data:", error)
      setIsLoadingPageInfo(false)
      setIsLoadingGraphData(false)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    fetchPageInfo()
  }, [selectedPageId])

  // Prepare data for the graph
  const prepareGraphData = () => {
    if (!graphData.length) return { data: {}, options: {} }

    const labels = graphData[0]?.values.map((point) => new Date(point.end_time).toLocaleDateString())

    const viewsData = graphData[0]?.values.map((point) => point.value) || []
    const impressionsData = graphData[1]?.values.map((point) => point.value) || []
    const engagementsData = graphData[2]?.values.map((point) => point.value) || []

    const data = {
      labels,
      datasets: [
        {
          label: "Page Views",
          data: viewsData,
          borderColor: "#FF5733",
          backgroundColor: "rgba(255, 87, 51, 0.2)",
          tension: 0.4,
        },
        {
          label: "Page Impressions",
          data: impressionsData,
          borderColor: "#33A1FF",
          backgroundColor: "rgba(51, 161, 255, 0.2)",
          tension: 0.4,
        },
        {
          label: "Post Engagements",
          data: engagementsData,
          borderColor: "#28A745",
          backgroundColor: "rgba(40, 167, 69, 0.2)",
          tension: 0.4,
        },
      ],
    }

    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          beginAtZero: true,
        },
      },
    }

    return { data, options }
  }

  const { data, options } = prepareGraphData()

  // Skeleton loaders
  const PagesSkeleton = () => (
    <div className="topbar-skeleton">
      {[1, 2, 3].map((item) => (
        <div key={item} className="skeleton-item pulse"></div>
      ))}
    </div>
  )

  const HeaderSkeleton = () => (
    <div className="header-skeleton">
      <div className="skeleton-title pulse"></div>
      <div className="skeleton-text pulse"></div>
      <div className="skeleton-text pulse"></div>
    </div>
  )

  const IconsSkeleton = () => (
    <div className="icons-wrapper">
      <div className="icons-container">
        {[1, 2, 3].map((item) => (
          <div key={item} className="icon-skeleton pulse">
            <div className="skeleton-icon"></div>
            <div className="skeleton-text-sm"></div>
            <div className="skeleton-text-sm"></div>
          </div>
        ))}
      </div>
    </div>
  )

  const GraphSkeleton = () => (
    <div className="graph-container">
      <div className="skeleton-title pulse"></div>
      <div className="graph-tags">
        {[1, 2, 3].map((item) => (
          <div key={item} className="skeleton-tag pulse"></div>
        ))}
      </div>
      <div className="graph skeleton-graph pulse"></div>
    </div>
  )

  return (
    <div>
      <div className="topbar">
        {isLoadingPages ? (
          <PagesSkeleton />
        ) : (
          pages.map((item) => (
            <div
              key={item.id}
              onClick={() => {
                setSelectedPageId(item.id)
                setPageAccessToken(item.access_token)
              }}
              className={selectedPageId === item.id ? "active" : ""}
            >
              <p>{item.name}</p>
            </div>
          ))
        )}
      </div>

      <div className="analytics-container">
        {isLoadingPageInfo ? (
          <HeaderSkeleton />
        ) : (
          <>
            <h1>Analytics for {selectedPage?.name || "No Page Selected"}</h1>
            <p className="analytics-para">
              <User color="#FF4177" /> Total Followers: {selectedPage.followers_count || "N/A"}
            </p>
            <p className="analytics-para">
              <FanIcon color="#FF4177" /> Total Fans: {selectedPage.fan_count || "N/A"}
            </p>
          </>
        )}

        {isLoadingPageInfo ? (
          <IconsSkeleton />
        ) : (
          <div className="icons-wrapper">
            <div className="icons-container">
              <div>
                <CameraIcon />
                <p>Total Photos</p>
                <p>{selectedPage.photos?.data?.length || "N/A"}</p>
              </div>

              <div>
                <Video />
                <p>Total Videos</p>
                <p>{selectedPage.videos?.data?.length || "N/A"}</p>
              </div>

              <div>
                <PictureInPicture2Icon />
                <p>Total Posts</p>
                <p>{selectedPage.published_posts?.data?.length || "N/A"}</p>
              </div>
            </div>
          </div>
        )}

        {isLoadingGraphData ? (
          <GraphSkeleton />
        ) : (
          <div className="graph-container">
            <h2>Page Insights: </h2>
            <div className="graph-tags">
              <p>
                <span className="page-views"></span> Page Views
              </p>
              <p>
                <span className="page-impressions"></span> Page Impressions
              </p>
              <p>
                <span className="post-engagement"></span> Post Engagement
              </p>
            </div>
            <div className="graph">
              {graphData.length ? (
                <Line data={data} options={options} />
              ) : (
                <div className="no-data-container">
                  <Loader2 className="animate-spin" size={40} />
                  <p>No graph data available</p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

