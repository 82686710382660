import { useState, useEffect } from "react";
import FacebookLogin from "react-facebook-login";
import { useNavigate } from "react-router-dom";

function FacebookLoginComponent() {
  const [user, setUser] = useState({});
  const [isExpired, setIsExpired] = useState(true);

  const image = localStorage.getItem("image");
  const fb_name = localStorage.getItem("fb_name");
  const navigate = useNavigate();
  

  useEffect(() => {
    const storedExpiryDate = localStorage.getItem("expireyDate");

    if (storedExpiryDate) {
      const expiryDate = new Date(parseInt(storedExpiryDate) * 1000);
      setIsExpired(expiryDate < new Date());
    }
  }, []);

  const handleFacebookCallback = (response) => {
    if (response?.status === "unknown") {
      console.error("Sorry!", "Something went wrong with Facebook login.");
      return;
    }
    console.log(response);

    // Store access token and expiration date in localStorage
    localStorage.setItem("access_token", response.accessToken);
    localStorage.setItem("expireyDate", response.data_access_expiration_time);
    localStorage.setItem("image", response.picture?.data?.url);
    localStorage.setItem("fb_name", response.name);

    // Update expiry status
    const expiryDate = new Date(response.data_access_expiration_time * 1000);
    setIsExpired(expiryDate < new Date());

    setTimeout(() => {
      window.location.reload();
    }, 1000)
  };

  const disconnect = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("expireyDate");
    localStorage.removeItem("image");
    localStorage.removeItem("fb_name");

    navigate("/facebook");
    setIsExpired(true); 
    window.location.reload();   
  }



  return (
    <>
      {isExpired ? (
        <FacebookLogin
          buttonStyle={{ padding: "6px" }}
          appId="876516320855801"
          autoLoad={false}
          fields="name,email,picture"
          scope="public_profile,email,pages_show_list,pages_manage_posts,pages_read_engagement"
          callback={handleFacebookCallback}
        />
      ) : (
        <div>
          <img src={image} alt="user"/>
          <p>Welcome, {fb_name || "User"}!</p>
          <button onClick={disconnect}>Disconnect</button>
        </div>
      )}
    </>
  );
}

export default FacebookLoginComponent;
